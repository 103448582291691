.LoginContainer{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    // background-color: var(--bg-color);
}
.LoginContainer .illustrationbox{
    width: 55%;

}
.LoginContainer .illustration{
    width: 90%;
    background-color: bisque;
}

.loginbox{
    width: 350px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* background-color: rgb(240, 237, 237); */
    box-shadow: 5px 5px  15px rgb(212, 212, 212);
    border-radius: 5px;
}
.LoginContainer .logo{
    width: 150px;
    margin: 50px;

}
.LoginContainer .loginitem{
    width: 80%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* height: 30px; */
    margin: 10px 0;
    background-color: rgb(250, 250, 250)  ;
    border-radius: 5px;
    // padding-left: 0;
    border: 1px solid rgb(179, 178, 178);
}
.LoginContainer .loginitem:hover{
    box-shadow: 5px 5px  15px rgb(212, 212, 212);

}

.LoginContainer .loginitem input{
    background:none;
    border: none;
    font-size: 15px;
    width: 95%;
    /* margin-top: 10px; */
    margin: 10px 0;
    
}
.LoginContainer .loginitem input:focus{
    outline: none;
    background:none;
}
.LoginContainer .forgot_text{
    font-size: 11px;
    width: 80%;
    text-align: end;
    margin: 0;
    margin-bottom: 10px;
}
.LoginContainer .submit_btn{
    width: 50%;
    background-color: blue;
    color: white;
    border: none;
    font-size: 15px;
    margin: 10px 0;
    padding: 15px 0;
    border-radius: 5px;
    /* box-shadow: 5px 5px  15px rgb(212, 212, 212); */
    text-transform: uppercase;
    border: 1px solid blue;

}
.LoginContainer .submit_btn:hover{
    box-shadow: 5px 5px  15px rgb(212, 212, 212);
    border: 1px solid blue;
    background-color: white;
    color: blue;
}
.LoginContainer .bottom_text{
    font-size: 13px;
    margin: 15px 0;
}
.LoginContainer .bottom_text span{
    font-weight: bold;
}

