.tablenew{
    width: 100%;

    border-collapse: collapse;
    border-color: black !important;
    border-width: 0 !important;
}
.tablenew thead{
    position: sticky;
    top: 0;

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-width: 0px;
    border:unset !important;
    border-style: solid !important;
    border-width: 0px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    box-sizing: border-box;
    padding: 50px;
    

    /* border-color: black !important; */


}
.tablenew td{

    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-width: 0px;
    border:unset !important;
    border-style: solid !important;
    border-width: 0px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    border-color: black !important;
    border-width: 1 !important;


}
